// extracted by mini-css-extract-plugin
export var btn = "projects-module--btn--KAg+O";
export var container = "projects-module--container--zAJZf";
export var post = "projects-module--post--OkYqo";
export var postContainer = "projects-module--postContainer--fZNCP";
export var row = "projects-module--row--nrjDC";
export var sectionGroup = "projects-module--sectionGroup--bnBRM";
export var sectionWrapper = "projects-module--sectionWrapper--0H0PA";
export var skill = "projects-module--skill--cC38B";
export var subTitle = "projects-module--subTitle--YC7Jf";
export var title = "projects-module--title--i8Rl0";